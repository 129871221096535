import Vue from "vue";

function addZero(val) {
  if (val < 10) {
    return "0" + val;
  } else {
    return val;
  }
}

//格式化时间
Vue.filter("formatTime", function (value, type = "YMD") {
  if (!value) return null;
  var dataTime = "";
  var data = new Date(value);
  var year = data.getFullYear();
  var month = addZero(data.getMonth() + 1);
  var day = addZero(data.getDate());
  var hour = addZero(data.getHours());
  var minute = addZero(data.getMinutes());
  var second = addZero(data.getSeconds());
  if (type == "YMD") {
    dataTime = year + "-" + month + "-" + day;
  } else if (type == "YMDHMS") {
    dataTime =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  } else if (type == "HMS") {
    dataTime = hour + ":" + minute + ":" + second;
  } else if (type == "YM") {
    dataTime = year + "-" + month;
  }
  return dataTime; //将格式化后的字符串输出到前端显示
});

//用户状态status
Vue.filter("status", function (status) {
  if (status == -1) return "审核不通过";
  else if (status == 0) return "审核中";
  else if (status == 1) return "审核通过";
});

//会员类型
Vue.filter("vip", function (opt) {
  if (opt == 0) return "非会员";
  else if (opt == 1) return "普通会员";
  else if (opt == 2) return "超级会员";
});

//数据来源
Vue.filter("sourceType", function (opt) {
  if (opt == 0) return "平台自建";
  else if (opt == 1) return "后台导入";
});

//性别
Vue.filter("sex", function (opt) {
  if (opt == 0) return "男";
  else if (opt == 1) return "女";
});

//玩法
Vue.filter("recordType", function (opt) {
  if (opt == 'dd') return "单点";
  else if (opt == 'zx') return "直选";
  else if (opt == 'z6') return "组选组六";
  else if (opt == 'z3') return "组选组三";
  else if (opt == 'z6_4m') return "组六-4码";
  else if (opt == 'z6_5m') return "组六-5码";
  else if (opt == 'z6_6m') return "组六-6码";
  else if (opt == 'z6_7m') return "组六-7码";
  else if (opt == 'z6_8m') return "组六-8码";
  else if (opt == 'z6_9m') return "组六-9码";
  else if (opt == 'z6qm') return "组六-10码";
  else if (opt == 'z3_2m') return "组三-2码";
  else if (opt == 'z3_3m') return "组三-3码";
  else if (opt == 'z3_4m') return "组三-4码";
  else if (opt == 'z3_5m') return "组三-5码";
  else if (opt == 'z3_6m') return "组三-6码";
  else if (opt == 'z3_7m') return "组三-7码";
  else if (opt == 'z3_8m') return "组三-8码";
  else if (opt == 'z3_9m') return "组三-9码";
  else if (opt == 'z3qb') return "组三-10码";
  else if (opt == 'dw_1m') return "定位-1码";
  else if (opt == 'dw_2m') return "定位-2码";
  else if (opt == 'bdw_2m') return "双码不定位";
});
