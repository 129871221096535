import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index"),
    meta: {
      name: "login",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/profile"),
    meta: {
      name: "profile",
    },
  },
  {
    path: "/deposit",
    name: "deposit",
    component: () => import("@/views/deposit"),
    meta: {
      name: "deposit",
    },
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("@/views/withdraw"),
    meta: {
      name: "withdraw",
    },
  },
  {
    path: "/vipMSP",
    name: "vipMSP",
    component: () => import("@/views/vipMSP"),
    meta: {
      name: "vipMSP",
    },
  },
  {
    path: "/changeLoginPassword",
    name: "changeLoginPassword",
    component: () => import("@/views/changeLoginPassword"),
    meta: {
      name: "changeLoginPassword",
    },
  },
  {
    path: "/tradePassword",
    name: "tradePassword",
    component: () => import("@/views/tradePassword"),
    meta: {
      name: "tradePassword",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/about"),
    meta: {
      name: "About",
    },
  },
  {
    path: "/invite",
    name: "invite",
    component: () => import("@/views/invite"),
    meta: {
      name: "invite",
    },
  },
  {
    path: "/signUp",
    name: "signUp",
    component: () => import("@/views/signUp"),
    meta: {
      name: "signUp",
    },
  },
  {
    path: "/resetLoginPassword",
    name: "resetLoginPassword",
    component: () => import("@/views/resetLoginPassword"),
    meta: {
      name: "resetLoginPassword",
    },
  },
  {
    path: "/taskHall",
    name: "taskHall",
    component: () => import("@/views/taskHall"),
    meta: {
      name: "taskHall",
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/order"),
    meta: {
      name: "order",
    },
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/message"),
    meta: {
      name: "message",
    },
  },
  {
    path: "/publish",
    name: "publish",
    component: () => import("@/views/publish"),
    meta: {
      name: "publish",
    },
  },
  {
    path: "/rechargeRecord",
    name: "rechargeRecord",
    component: () => import("@/views/rechargeRecord"),
    meta: {
      name: "rechargeRecord",
    },
  },
  {
    path: "/banlanceRecord",
    name: "banlanceRecord",
    component: () => import("@/views/banlanceRecord"),
    meta: {
      name: "banlanceRecord",
    },
  },
  {
    path: "/withdrawRecord",
    name: "withdrawRecord",
    component: () => import("@/views/withdrawRecord"),
    meta: {
      name: "withdrawRecord",
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/layout"),
    children: [
      {
        path: "/home/index",
        name: "index",
        component: () => import("@/views/home"),
        meta: {
          name: "Home",
        },
      },
      {
        path: "/home/mboard",
        name: "mboard",
        component: () => import("@/views/mboard"),
        meta: {
          name: "mboard",
        },
      },
      {
        path: "/home/mine",
        name: "mine",
        component: () => import("@/views/mine"),
        meta: {
          name: "mine",
        },
      },
     
    ]
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
