import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import getAjax from "@/utils/requestAjax";
import "./utils/filter.js";
import "@/assets/less/master.css";
import "@/assets/less/animation.css";
import "@/assets/less/forms.css";
import "@/assets/fonts/iconfont.css";
import "@/assets/less/layout.css";
import "@/assets/less/common.less";
import "@/assets/less/inputPlate.css";
import "@/assets/less/swiper.css";
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/utils/defaultFunc.js' //默认方法
import $ from 'jquery'
Vue.prototype.$ = $

import '@/utils/js/togglepassword.js' //默认方法
// import '@/utils/js/modal.js' //默认方法

Vue.use(Vant);
Vue.config.productionTip = false;

// 挂载request
Vue.prototype.getAjax = getAjax;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
