import jQuery from 'jquery'
(function ($) {
    $.fn.togglePassword = function (options) {
        var s = $.extend($.fn.togglePassword.defaults, options),
            input = $(this);

        $(s.el).bind(s.ev, function () {
            if ("password" == $(input).attr("type")) {
                $(input).attr("type", "text")
                $('#eye').addClass("reveal").removeClass("secret");
            }
            else {
                $(input).attr("type", "password");
                $('#eye').addClass("secret").removeClass("reveal");
            }
        });
    };

    $.fn.togglePassword.defaults = {
        ev: "click"
    };
}(jQuery));

