import axios from "axios";
import { Message } from "element-ui";
const getAjax = async (url, requestParams, method, way = 'formData') => {
    return new Promise((resolve) => {
        let params = {};
        let data = {};
        let formData = new FormData();
        if (way === 'formData') {
            for (let i in requestParams) {
                formData.append(i, requestParams[i]);
            }
        }
        if (method === "get") {
            params = way === 'formData' ? formData : requestParams;
        } else {
            data = way === 'formData' ? formData : requestParams;
        }
        axios({
            method,
            url: '/' + url,
            responseType: "json", /// 设置相应头
            // `headers`是要发送的自定义请求头
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                // "Content-Type": "application/json",
                token: localStorage.getItem("token"),
            },
            data,
            params,
        }).then((res) => {
            resolve(res.data);
        });
    });
};
export default getAjax;
