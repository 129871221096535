import Vue from "vue";
import { Message, MessageBox } from "element-ui";
import $ from 'jquery'
import { Notify } from 'vant';


/**
 * 调用接口数据
 * @param {*} url 接口url
 * @param {*} requestParams 相关参数
 * @param {*} method 方法名称
 * @param {*} options 其他参数
 * @returns
 */

export function randomNumber() {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  if (arguments.length === 1) {
    let [length] = arguments;
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) =>
      i > 0 ? random(0, 9) : random(1, 9)
    );
    return parseInt(nums.join(""));
  } else if (arguments.length >= 2) {
    let [min, max] = arguments;
    return random(min, max);
  } else {
    return Number.NaN;
  }
}

/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(length, chats) {
  if (!length) length = 1;
  if (!chats) chats = "0123456789qwertyuioplkjhgfdsazxcvbnm";
  let str = "";
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1);
    str += chats[num];
  }
  return str;
}

/**
 * 从sessionStorage中获取指定键的值
 * @param {*} length
 * @param {*} chats
 * @returns
 */
export function getItem(key) {
  try {
    const value = sessionStorage.getItem(key);
    return isJSONString(value) ? JSON.parse(value) : value;
  } catch (error) {
    console.error(`${error}`);
    return null;
  }
}

/**
 * 将键值对存储到sessionStorage中
 * @param {*} length
 * @param {*} chats
 * @returns
 */
export function setItem(key, value) {
  try {
    const serializedValue =
      typeof value === "object" ? JSON.stringify(value) : value;
    sessionStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error(`${error}`);
  }
}

/**
 * 从sessionStorage中移除指定键的值
 * @param {*} length
 * @param {*} chats
 * @returns
 */
export function removeItem(key) {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error(` ${error}`);
  }
}

//检查字符串是否是JSON格式
function isJSONString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * 重新加载主题
 * @param {*} themeName 主题名称
 * @returns
 */
export function loadTheme(themeName) {
  if (!themeName) return;
  const head = document.getElementsByTagName("head")[0];
  const link = document.getElementById("theme-style");
  if (link) {
    link.href = `/theme/${themeName}.css`;
  } else {
    const newLink = document.createElement("link");
    newLink.id = "theme-style";
    newLink.rel = "stylesheet";
    newLink.href = `/theme/${themeName}.css`;
    head.appendChild(newLink);
  }
}

/**
 * 重新加载风格
 * @param {*} styleName 风格名称
 * @returns
 */
export function loadStyle(styleName) {
  if (!styleName) return;
  const head = document.getElementsByTagName("head")[0];
  const link = document.getElementById("item-style");
  if (link) {
    link.href = `/style/${styleName}.css`;
  } else {
    const newLink = document.createElement("link");
    newLink.id = "item-style";
    newLink.rel = "stylesheet";
    newLink.href = `/style/${styleName}.css`;
    head.appendChild(newLink);
  }
}

/**
 * 统一封装消息提示，适用于简单提示
 * @param {*} message 消息内容
 * @param {*} type 消息类型
 * @param {*} options 其他参数
 */
export function message(message, type, options) {
  Message({
    type: type || "success",
    message,
    ...options,
  });
}

/**
 * 弹出确认框
 * @param {*} message 消息内容
 * @param {*} sfun 成功回调
 * @param {*} options 其他参数
 */
export function confirm(message, sfun, title, options) {
  MessageBox({
    message,
    title: title || '温馨提示',
    type: 'warning',
    showCancelButton: true,
    ...options
  }).then(() => {
    if (sfun) sfun();
  });
}
//格式化时间
export function formatTime(value, type = "YMDHMS") {
  if (!value) return null;
  var dataTime = "";
  var data = new Date(value);
  var year = data.getFullYear();
  var month = addZero(data.getMonth() + 1);
  var day = addZero(data.getDate());
  var hour = addZero(data.getHours());
  var minute = addZero(data.getMinutes());
  var second = addZero(data.getSeconds());
  if (type == "YMD") {
    dataTime = year + "-" + month + "-" + day;
  } else if (type == "YMDHMS") {
    dataTime =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  } else if (type == "HMS") {
    dataTime = hour + ":" + minute + ":" + second;
  } else if (type == "YM") {
    dataTime = year + "-" + month;
  } else if (type == "MS") {
    dataTime = minute + ":" + second
  }
  return dataTime; //将格式化后的字符串输出到前端显示
}

function addZero(val) {
  if (val < 10) {
    return "0" + val;
  } else {
    return val;
  }
}

//iconID 1:warning  2:succeed   3:error  4:loding
export function toast(title, html, sort, holdTime, iconID) {
  $('.toastBox').remove();
  let toastHtml = "";
  if (iconID == 4) {
    toastHtml += `<div class="toastBox toastStatus` + iconID + `  display alignCenter" id="toastSort_` + sort + `">`;
    toastHtml += `<div class="overallShade"></div>`;
    toastHtml += `<div class="toastCont display alignCenter justifyCenter">`;
    toastHtml += `
                  <div class="lodingDot">
                      <div class="support">
                          <div class="dot"></div>
                      </div>
                      <div class="support">
                          <div class="dot"></div>
                      </div>
                      <div class="support">
                          <div class="dot"></div>
                      </div>
                      <div class="support">
                          <div class="dot"></div>
                      </div>
                      <div class="support">
                          <div class="dot"></div>
                      </div>
                      <div class="support">
                          <div class="dot"></div>
                      </div> 
                  </div>
                  `;
    toastHtml += `</div>`;
    toastHtml += `</div>`;


  } else {
    toastHtml += `<div class="toastBox h5MaxWidth toastStatus` + iconID + `  display alignStart" id="toastSort_` + sort + `">`;
    toastHtml += `<div class="overallShade h5MaxWidth"></div>`;
    toastHtml += `<div class="toastCont bounceInDown et-02s display alignStart">`;
    // toastHtml += `<div class="icon"><i class="iconfont icon-StatusIcon` + iconID + `"></i></div>`;
    toastHtml += `<div class="tipText flex1"><p>` + html + `</p></div>`;
    toastHtml += `</div>`;
    toastHtml += `</div>`;
  }

  $('body').append(toastHtml);
  if (holdTime != "") { removeToast(sort, 2000) }
};

export function removeToast(sort, holdTime = 200) {
  setTimeout(() => {
    $('#toastSort_' + sort).remove();
  }, holdTime)
}

export function tips(message, type = 'success') {
  Notify({ type, message });
}

export function copyText(text) {
  const input = document.createElement('input');
  input.style.position = 'fixed';
  input.style.opacity = 0;
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
}

Vue.prototype.getItem = getItem;
Vue.prototype.setItem = setItem;
Vue.prototype.removeItem = removeItem;
Vue.prototype.loadTheme = loadTheme;
Vue.prototype.loadStyle = loadStyle;
Vue.prototype.Msg = message;
Vue.prototype.confirm = confirm;
Vue.prototype.toast = toast;
Vue.prototype.removeToast = removeToast;
Vue.prototype.formatTime = formatTime;
Vue.prototype.copyText = copyText;
Vue.prototype.tips = tips;
Vue.prototype.$bus = new Vue();

export default { randomString };
